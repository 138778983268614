import React, { useState, useEffect, useRef, useCallback } from 'react';

const styles = `
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  }

  .app {
    min-height: 100vh;
    background-color: #0a0a0f;
    color: white;
  }

  .loading-screen {
    position: fixed;
    inset: 0;
    background-color: #0a0a0f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  .loading-text {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .loading-progress {
    width: 200px;
    height: 4px;
    background-color: #1c1c22;
    border-radius: 2px;
    overflow: hidden;
  }

  .loading-bar {
    height: 100%;
    background-color: #8b5cf6;
    transition: width 0.3s ease;
  }

  .welcome-screen {
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }

  .welcome-title {
    font-size: 4rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .welcome-subtitle {
    font-size: 1.5rem;
    opacity: 0.9;
    margin-bottom: 3rem;
  }

  .button-grid {
    display: grid;
    gap: 1rem;
    width: 100%;
    max-width: 800px;
  }

  @media (min-width: 768px) {
    .button-grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  .button {
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }

  .button:hover {
    transform: scale(1.02);
  }

  .button-secondary {
    background-color: #141419;
  }

  .button-secondary:hover {
    background-color: #1c1c22;
  }

  .button-primary {
    background-color: #6d28d9;
    grid-column: 1 / -1;
  }

  .button-primary:hover {
    background-color: #7c3aed;
  }

  .slideshow {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }

  .slideshow-container {
    width: 100%;
    max-width: 1400px;
    background-color: rgba(20, 20, 25, 0.95);
    border-radius: 1rem;
    overflow: hidden;
    backdrop-filter: blur(20px);
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 80vh;
    background-color: #0a0a0f;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slide-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .slide-image.loaded {
    opacity: 1;
  }

  .nav-buttons {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    opacity: 0;
    transition: opacity 0.3s ease;
    background: linear-gradient(90deg, rgba(0,0,0,0.3) 0%, transparent 15%, transparent 85%, rgba(0,0,0,0.3) 100%);
  }

  .image-container:hover .nav-buttons {
    opacity: 1;
  }

  .nav-button {
    background-color: rgba(20, 20, 25, 0.8);
    border: none;
    color: white;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-button:hover {
    background-color: rgba(28, 28, 34, 0.9);
  }

  .info-container {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .artwork-info h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .artwork-info p {
    font-size: 1.2rem;
    opacity: 0.9;
  }

  .music-info {
    text-align: right;
  }

  .music-info h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .music-info p {
    font-size: 1.2rem;
    opacity: 0.9;
  }

  .audio-controls {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 50;
  }

  .audio-buttons {
    display: flex;
    gap: 0.5rem;
  }

  .audio-button {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: rgba(20, 20, 25, 0.8);
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .audio-button:hover {
    background-color: rgba(28, 28, 34, 0.9);
    transform: scale(1.05);
  }

  .exit-button {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 50;
  }

  .exit-button:hover {
    transform: translateX(-50%); /* Prevent scaling or shifting on hover */
    background-color: #1c1c22; /* Optional: Add a subtle color change */
  }

  .time-control {
  position: fixed;
  bottom: 6rem;
  right: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: rgba(20, 20, 25, 0.8);
  padding: 1rem;
  border-radius: 0.5rem;
  backdrop-filter: blur(8px);
}

.progress-dial {
  width: 50px;
  height: 50px;
}

.time-slider {
  width: 120px;
  height: 4px;
  background-color: #1c1c22;
  border-radius: 2px;
  -webkit-appearance: none;
  appearance: none;
}

.time-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: #8b5cf6;
  border-radius: 50%;
  cursor: pointer;
}

.time-slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background-color: #8b5cf6;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

`;

const slideshows = {
  "French Impressionism 🎨": {
    songs: [
      {
        title: "Gaspard de la Nuit, 1. Ondine",
        url: "http://d19bhbirxx14bg.cloudfront.net/ravel-ondine-sasaki.mp3",
        artist: "Maurice Ravel"
      },
      {
        title: "Gaspard de la Nuit, 2. Le Gibet",
        url: "http://d19bhbirxx14bg.cloudfront.net/ravel-le-gibet-sasaki.mp3",
        artist: "Maurice Ravel"
      },
      {
      title: "Gaspard de la Nuit, 3. Scarbo",
      url: "http://d19bhbirxx14bg.cloudfront.net/ravel-scarbo-sasaki.mp3",
      artist: "Maurice Ravel"
    }
    ],
    slides: [
      {
        title: "Impression, Sunrise",
        url: "https://upload.wikimedia.org/wikipedia/commons/5/54/Claude_Monet%2C_Impression%2C_soleil_levant.jpg",
        artist: "Claude Monet"
      },
      {
        title: "La Loge",
        url: "https://en.wikipedia.org/wiki/La_Loge#/media/File:La_Loge_de_P.-A._Renoir_(Fondation_Vuitton,_Paris)_(46499625955).jpg",
        artist: "Pierre-Auguste Renoir"
      },
      {
        title: "Snow at Port-Marly",
        url: "https://upload.wikimedia.org/wikipedia/commons/d/de/Lille_PdBA_sisley_port_marly.JPG",
        artist: "Alfred Sisley"
      },
      {
        title: "Woman with a Parasol",
        url: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Claude_Monet_-_Woman_with_a_Parasol_-_Madame_Monet_and_Her_Son_-_Google_Art_Project.jpg",
        artist: "Claude Monet"
      },
      {
        title: "The Dance Class",
        url: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Edgar_Degas_The_Dance_Class.jpg",
        artist: "Edgar Degas"
      },
      {
        title: "Water Lilies",
        url: "https://lh3.googleusercontent.com/ICG3o0i2AHhl3EoRSVoipVzFig2IJPiHHYGDGdzY4m3zD0BZuydQ2J1vUWIn0rkrrA=s1200",
        artist: "Claude Monet"
      },
      {
  title: "Colonna Romano",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Pierre-Auguste_Renoir_-_Colonna_Romano.jpg/1920px-Pierre-Auguste_Renoir_-_Colonna_Romano.jpg",
  artist: "Pierre-Auguste Renoir"
},
{
  title: "Le Printemps",
  url: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Edouard_Manet_023.jpg",
  artist: "Édouard Manet"
},
{
  title: "Luncheon on the Grass",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Edouard_Manet_-_Luncheon_on_the_Grass_-_Google_Art_Project.jpg/2560px-Edouard_Manet_-_Luncheon_on_the_Grass_-_Google_Art_Project.jpg",
  artist: "Édouard Manet"
},
{
  title: "Olympia",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Edouard_Manet_-_Olympia_-_Google_Art_Project_3.jpg/2880px-Edouard_Manet_-_Olympia_-_Google_Art_Project_3.jpg",
  artist: "Édouard Manet"
},
{
  title: "Self Portrait",
  url: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Edouard_Manet_060.jpg",
  artist: "Édouard Manet"
},
{
  title: "Le Chemin de fer",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Edouard_Manet_-_Le_Chemin_de_fer_-_Google_Art_Project.jpg/2560px-Edouard_Manet_-_Le_Chemin_de_fer_-_Google_Art_Project.jpg",
  artist: "Édouard Manet"
},
{
  title: "A Bar at the Folies-Bergère",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/%22Un_Bar_aux_Folies-Berg%C3%A8re%22_by_%C3%89douard_Manet_%281882%29.jpg/2560px-%22Un_Bar_aux_Folies-Berg%C3%A8re%22_by_%C3%89douard_Manet_%281882%29.jpg",
  artist: "Édouard Manet"
},
{
  title: "La Nymphe surprise",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/%C3%89douard_MANET_-_La_Nymphe_surprise_-_Google_Art_Project.jpg/1920px-%C3%89douard_MANET_-_La_Nymphe_surprise_-_Google_Art_Project.jpg",
  artist: "Édouard Manet"
},
{
  title: "Young Girls in a Boat",
  url: "https://www.albertina.at/site/assets/files/1811/claude-monet_junge-madchen-im-boot_1887_web-_c_-the-national-museum-of-western-art--tokio--sammlung-matsukata.1200x0.jpg",
  artist: "Claude Monet"
},
{
  title: "The Artist's Garden at Vétheuil",
  url: "https://static.ticimax.cloud/6389/uploads/urunresimleri/buyuk/claude-monet-the-artists-garden-at-vth-bc1848.jpg",
  artist: "Claude Monet"
},
{
  title: "Rouen Cathedral: The Portal (Sunlight)",
  url: "https://s3-eu-west-1.amazonaws.com/static1.goulandris.gr/shop/Artwork-Categories/Monet-Claude-La-Cathedrale-de-Rouen-le-matin-dominante-rose-911-s023.jpg",
  artist: "Claude Monet"
},
{
  title: "Impression, Sunrise",
  url: "https://fraryguitar.com/history_images/claude_Monet_Impression_soleil%20levant1874sm.jpg",
  artist: "Claude Monet"
},
{
  title: "Holiday in Mentone",
  url: "https://galeriemontblanc.com/cdn/shop/articles/original_68738f8e-40ec-419c-bd1f-22911b534281.jpg?v=1669480186",
  artist: "Charles Conder"
},
{
  title: "Manet with Dog",
  url: "https://lh3.googleusercontent.com/-5kysEx2zTD4aCQMXrIdygrGKrjQ7sN9dQUc-BidyWW-vE2z_g4fFPA-p2n9tSbpjA=s1200https://medias.gazette-drouot.com/prod/medias/mediatheque/71938.jpg",
  artist: "Édouard Manet"
},
{
  title: "La Negresse",
  url: "https://www.pinacoteca-agnelli.it/wp-content/uploads/2022/02/Manet-La-Negresse-pinacotecaAgnelli-2400x2967.jpg",
  artist: "Édouard Manet"
},
{
  title: "The Winter Garden in the Greenhouse",
  url: "https://www.meisterdrucke.it/kunstwerke/1260px/Edouard_Manet_-_The_winter_garden_in_the_greenhouse_Painting_by_Edouard_Manet_%281832-1883%29_1879_1_-_%28MeisterDrucke-961748%29.jpg",
  artist: "Édouard Manet"
},
{
  title: "Berthe Morisot and Her Sister Edma",
  url: "https://cdn.britannica.com/02/37702-050-C9A0B82C/Berthe-Morisot-Edma-paintings-Reading-Cleveland-Museum-of-Art.jpg",
  artist: "Berthe Morisot"
},
{
  title: "Girl with a Dog",
  url: "https://www.gamtorino.it/it/media/2024/08/03_Berthe-Morisot_Ragazza-con-cane-1.jpg",
  artist: "Berthe Morisot"
},
{
  title: "Girl and Potiche Vase",
  url: "https://www.apollo-magazine.com/wp-content/uploads/2018/10/Cat.-Girl-and-Potiche-Vase-1889.jpg",
  artist: "Berthe Morisot"
},
{
  title: "Lucie Léon at the Piano",
  url: "https://www.enciclopediadelledonne.it/edd.nsf/0/F3711E1A1FD33BE7C1258A0500547E4E/$file/lucie-leon-at-the-piano-1892.jpg",
  artist: "Berthe Morisot"
},
{
  title: "The Execution of Maximilian",
  url: "https://upload.wikimedia.org/wikipedia/commons/4/40/Edouard_Manet_022.jpg",
  artist: "Édouard Manet"
},
{
  title: "The Balcony",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Edouard_Manet_-_The_Balcony_-_Google_Art_Project.jpg/1920px-Edouard_Manet_-_The_Balcony_-_Google_Art_Project.jpg",
  artist: "Édouard Manet"
},
{
  title: "Gypsy with a Cigarette",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/%C3%89douard_Manet_-_Gitane_avec_une_cigarette.jpg/1920px-%C3%89douard_Manet_-_Gitane_avec_une_cigarette.jpg",
  artist: "Édouard Manet"
},
{
  title: "Masked Ball at the Opera",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Masked_Ball_at_the_Opera_%281873%29_Edouard_Manet_%28National_Gallery_of_Art%2C_Washington_D.C.%29.jpg/2560px-Masked_Ball_at_the_Opera_%281873%29_Edouard_Manet_%28National_Gallery_of_Art%2C_Washington_D.C.%29.jpg",
  artist: "Édouard Manet"
},
{
  title: "Boating",
  url: "https://upload.wikimedia.org/wikipedia/commons/4/43/Edouard_Manet_Boating.jpg",
  artist: "Édouard Manet"
},
{
  title: "Nana",
  url: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Edouard_Manet_037.jpg",
  artist: "Édouard Manet"
},
{
  title: "The Rue Mosnier with Flags",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/%C3%89douard_Manet%2C_The_Rue_Mosnier_with_Flags%2C_1878.jpg/2560px-%C3%89douard_Manet%2C_The_Rue_Mosnier_with_Flags%2C_1878.jpg",
  artist: "Édouard Manet"
},
{
  title: "In the Conservatory",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/In_the_Conservatory_-_edited.jpg/2560px-In_the_Conservatory_-_edited.jpg",
  artist: "Édouard Manet"
},
{
  title: "Summer or The Amazon",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/%27Summer%27_or_%27The_Amazon%27%2C_by_Edouard_Manet.jpg/1920px-%27Summer%27_or_%27The_Amazon%27%2C_by_%C3%89douard_Manet.jpg",
  artist: "Édouard Manet"
},
{
  title: "Music in the Tuileries",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/MANET_-_M%C3%BAsica_en_las_Tuller%C3%ADas_%28National_Gallery%2C_Londres%2C_1862%29.jpg/2880px-MANET_-_M%C3%BAsica_en_las_Tuller%C3%ADas_%28National_Gallery%2C_Londres%2C_1862%29.jpg",
  artist: "Édouard Manet"
},
{
  title: "Boy Blowing Bubbles",
  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Boy_blowing_bubbles_by_%C3%89douard_Manet_%281867%29.JPG/1920px-Boy_blowing_bubbles_by_%C3%89douard_Manet_%281867%29.JPG",
  artist: "Édouard Manet"
}
    ]
  }
};

const useImagePreloader = (slideshow) => {
  const [loadedCount, setLoadedCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [validSlides, setValidSlides] = useState([]);

  useEffect(() => {
    if (!slideshow) return;

    const totalImages = slideshow.slides.length;
    let loadedImages = 0;
    let validImagesList = [];
    const timeouts = [];

    const handleImageLoad = (index, url) => {
      loadedImages++;
      validImagesList.push(slideshow.slides[index]);
      setValidSlides([...validImagesList]);
      setLoadedCount((loadedImages / totalImages) * 100);
      if (loadedImages === totalImages) {
        setIsLoading(false);
      }
    };

    const handleImageError = (index, url) => {
      console.log(`Failed to load artwork: ${url}`);
      loadedImages++;
      setLoadedCount((loadedImages / totalImages) * 100);
      if (loadedImages === totalImages) {
        setIsLoading(false);
      }
    };

    slideshow.slides.forEach((slide, index) => {
      const img = new Image();
      img.onload = () => handleImageLoad(index, slide.url);
      img.onerror = () => handleImageError(index, slide.url);

      const timeoutId = setTimeout(() => {
        if (!validImagesList.includes(slideshow.slides[index])) {
          handleImageError(index, slide.url);
        }
      }, 10000);

      timeouts.push(timeoutId);
      img.src = slide.url;
    });

    return () => {
      timeouts.forEach(id => clearTimeout(id));
    };
  }, [slideshow]);

  return { isLoading, progress: loadedCount, validSlides };
};

const useMusicPlayer = (songs) => {
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 0 : 0.5;
    }
  }, [isMuted]);

  useEffect(() => {
    const audio = audioRef.current;

    const handleSongEnd = () => {
      setCurrentSongIndex(prev => (prev + 1) % songs.length);
    };

    if (audio) {
      audio.addEventListener('ended', handleSongEnd);
      return () => audio.removeEventListener('ended', handleSongEnd);
    }
  }, [songs.length]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = songs[currentSongIndex].url;
      audioRef.current.play().catch(e => console.log('Audio playback failed:', e));
    }
  }, [currentSongIndex, songs]);

  return {
    audioRef,
    currentSong: songs[currentSongIndex],
    isMuted,
    setIsMuted,
    nextSong: () => setCurrentSongIndex(prev => (prev + 1) % songs.length),
    previousSong: () => setCurrentSongIndex(prev => prev === 0 ? songs.length - 1 : prev - 1)
  };
};

const LoadingScreen = ({ progress }) => (
  <div className="loading-screen">
    <div className="loading-text">Loading Artworks... {Math.round(progress)}%</div>
    <div className="loading-progress">
      <div className="loading-bar" style={{ width: `${progress}%` }} />
    </div>
  </div>
);

const WelcomeScreen = ({ onSelect, onRandom }) => (
  <div className="welcome-screen">
    <h1 className="welcome-title">Welcome to high.is 🎨</h1>
    <p className="welcome-subtitle">by RCdS</p>

    <div className="button-grid">
      {Object.keys(slideshows).map((name) => (
        <button
          key={name}
          onClick={() => onSelect(name)}
          className="button button-secondary"
        >
          {name}
        </button>
      ))}

      <button
        onClick={onRandom}
        className="button button-primary"
      >
        Random Slideshow 🎲
      </button>
    </div>
  </div>
);

const Slideshow = ({ name, onExit, slides, songs }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentSlideInterval, setCurrentSlideInterval] = useState(15000);
  const [nextSlideInterval, setNextSlideInterval] = useState(15000);
  const [progress, setProgress] = useState(0);
  const [isLSDMode, setIsLSDMode] = useState(false);
  const progressRef = useRef(null);
  const slideStartTimeRef = useRef(Date.now());
  const {
    audioRef,
    currentSong,
    isMuted,
    setIsMuted,
    nextSong,
    previousSong
  } = useMusicPlayer(songs);

  const startNewSlide = useCallback(() => {
    setImageLoaded(false);
    setCurrentIndex(prev => prev === slides.length - 1 ? 0 : prev + 1);
    setProgress(0);
    setCurrentSlideInterval(nextSlideInterval);
    slideStartTimeRef.current = Date.now();
  }, [slides.length, nextSlideInterval]);

  const prevSlide = () => {
    setImageLoaded(false);
    setCurrentIndex(prev => prev === 0 ? slides.length - 1 : prev - 1);
    setProgress(0);
    setCurrentSlideInterval(nextSlideInterval);
    slideStartTimeRef.current = Date.now();
  };

  const adjustInterval = (change) => {
    setNextSlideInterval(prev => Math.min(Math.max(2000, prev + change), 15000));
  };

  useEffect(() => {
    let timer;
    if (isLSDMode) {
      timer = setInterval(startNewSlide, 200);
    } else {
      const remainingTime = Math.max(0, currentSlideInterval - (Date.now() - slideStartTimeRef.current));
      timer = setTimeout(() => {
        startNewSlide();
        const nextTimer = setInterval(() => startNewSlide(), nextSlideInterval);
        return () => clearInterval(nextTimer);
      }, remainingTime);
    }

    return () => {
      clearTimeout(timer);
      clearInterval(timer);
    };
  }, [currentSlideInterval, nextSlideInterval, isLSDMode, startNewSlide]);

  useEffect(() => {
    if (isLSDMode) return;

    const updateProgress = () => {
      const elapsed = Date.now() - slideStartTimeRef.current;
      const currentProgress = (elapsed / currentSlideInterval) * 100;

      if (currentProgress >= 100) {
        setProgress(0);
      } else {
        setProgress(currentProgress);
        progressRef.current = requestAnimationFrame(updateProgress);
      }
    };

    progressRef.current = requestAnimationFrame(updateProgress);

    return () => {
      if (progressRef.current) {
        cancelAnimationFrame(progressRef.current);
      }
    };
  }, [currentIndex, currentSlideInterval, isLSDMode]);

  const radius = 20;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${(circumference * progress) / 100} ${circumference}`;

  const willTimeUpdate = nextSlideInterval !== currentSlideInterval;

  return (
    <div className="slideshow">
      <div className="slideshow-container">
        <div className="image-container">
          <img
            src={slides[currentIndex].url}
            alt={slides[currentIndex].title}
            className={`slide-image ${imageLoaded ? 'loaded' : ''}`}
            onLoad={() => setImageLoaded(true)}
          />

          <div className="nav-buttons">
            <button className="nav-button" onClick={prevSlide}>⬅️</button>
            <button className="nav-button" onClick={startNewSlide}>➡️</button>
          </div>
        </div>

        <div className="info-container">
          <div className="artwork-info">
            <h2>{slides[currentIndex].title} 🖼️</h2>
            <p>by {slides[currentIndex].artist}</p>
          </div>
          <div className="music-info">
            <h3>{currentSong.title} 🎵</h3>
            <p>by {currentSong.artist}</p>
          </div>
        </div>
      </div>

      <div style={{
        position: 'fixed',
        bottom: '6rem',
        right: '2rem',
        backgroundColor: 'rgba(20, 20, 25, 0.8)',
        padding: '1rem',
        borderRadius: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        backdropFilter: 'blur(8px)',
        color: 'white'
      }}>
        <label style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          cursor: 'pointer'
        }}>
          <input
            type="checkbox"
            checked={isLSDMode}
            onChange={(e) => setIsLSDMode(e.target.checked)}
          />
          LSD Mode 🌈
        </label>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem'
        }}>
          {!isLSDMode && (
            <>
              <div style={{ position: 'relative', width: '50px', height: '50px' }}>
                <svg viewBox="0 0 50 50" width="50" height="50">
                  <circle
                    cx="25"
                    cy="25"
                    r={radius}
                    fill="none"
                    stroke="#1c1c22"
                    strokeWidth="4"
                  />
                  <circle
                    cx="25"
                    cy="25"
                    r={radius}
                    fill="none"
                    stroke="#8b5cf6"
                    strokeWidth="4"
                    strokeDasharray={strokeDasharray}
                    transform="rotate(-90 25 25)"
                  />
                  <text
                    x="25"
                    y="25"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill="white"
                    fontSize="10"
                  >
                    {currentSlideInterval / 1000}s
                  </text>
                </svg>
              </div>

              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '0.5rem',
                position: 'relative'
              }}>
                <button
                  onClick={() => adjustInterval(1000)}
                  disabled={nextSlideInterval >= 15000}
                  style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '1.2rem',
                    opacity: nextSlideInterval >= 15000 ? 0.5 : 1
                  }}
                >
                  ⬆️
                </button>
                {willTimeUpdate && (
                  <div style={{
                    fontSize: '0.6rem', /* Slightly larger font */
                    color: '#8b5cf6',
                    textAlign: 'left',
                    marginTop: '8px', /* Add a small gap */
                    position: 'absolute',
                    top: '-1.5rem',
                    left: '10%',
                    transform: 'translateX(-120%)', /* Center horizontally */
                    whiteSpace: 'nowrap'
                  }}>
                    Next: {nextSlideInterval / 1000}s
                  </div>
                )}
                <button
                  onClick={() => adjustInterval(-1000)}
                  disabled={nextSlideInterval <= 2000}
                  style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '1.2rem',
                    opacity: nextSlideInterval <= 2000 ? 0.5 : 1
                  }}
                >
                  ⬇️
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="audio-controls">
        <audio ref={audioRef} autoPlay />
        <div className="audio-buttons">
          <button className="audio-button" onClick={previousSong}>⏮️</button>
          <button className="audio-button" onClick={() => setIsMuted(!isMuted)}>
            {isMuted ? '🔇' : '🔊'}
          </button>
          <button className="audio-button" onClick={nextSong}>⏭️</button>
        </div>
      </div>

      <button className="button button-secondary exit-button" onClick={onExit}>
        Exit Slideshow 🚪
      </button>
    </div>
  );
};
          const ArtMusicSlideshow = () => {
            const [selectedShow, setSelectedShow] = useState(null);
            const { isLoading, progress, validSlides } = useImagePreloader(
              selectedShow ? slideshows[selectedShow] : null
            );

            const handleShowSelect = (name) => {
              setSelectedShow(name);
            };

            const handleRandom = () => {
              const shows = Object.keys(slideshows);
              const randomShow = shows[Math.floor(Math.random() * shows.length)];
              setSelectedShow(randomShow);
            };

            const handleExit = () => {
              setSelectedShow(null);
            };

            useEffect(() => {
              const styleElement = document.createElement('style');
              styleElement.innerHTML = styles;
              document.head.appendChild(styleElement);
              return () => document.head.removeChild(styleElement);
            }, []);

            return (
              <div className="app">
                {!selectedShow ? (
                  <WelcomeScreen
                    onSelect={handleShowSelect}
                    onRandom={handleRandom}
                  />
                ) : isLoading ? (
                  <LoadingScreen progress={progress} />
                ) : (
                  <Slideshow
                    name={selectedShow}
                    slides={validSlides}
                    songs={slideshows[selectedShow].songs}
                    onExit={handleExit}
                  />
                )}
              </div>
            );
          };

          export default ArtMusicSlideshow;
